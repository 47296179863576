@charset "UTF-8";
@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Bold.eot");
  src: url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-ExtraBold.eot");
  src: url("../fonts/ProximaNova-ExtraBold.woff") format("woff"), url("../fonts/ProximaNova-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Semibold.eot");
  src: url("../fonts/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

.trs,
.top-form__input,
.top-form__label,
.top-form__btn,
.package__btn,
.selection__input,
.selection__label,
.selection__btn,
.services__image img,
.services__order,
.services__el,
.preparation__input,
.preparation__label,
.preparation__btn,
.consult__input,
.consult__textarea,
.consult__label,
.consult__btn,
.footer__phone,
.footer__mail,
.btn,
.menu__link,
.order__input,
.order__label,
.order__btn,
.mobile__btn {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }

*,
*:before,
*:after {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0 none;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%;
  font-family: "ProximaNova", sans-serif;
  color: #3d3d3d;
  font-size: 1rem;
  line-height: 1; }

ul,
li {
  list-style-type: none; }

p {
  margin: 0; }

a {
  text-decoration: none;
  color: #000; }

img {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

textarea {
  resize: none; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

.red {
  color: #dc385a; }

.purple {
  color: #83599c; }

.blue {
  color: #1792e7; }

.remodal-wrapper {
  padding: 0; }

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

/* Стили секции header */
.header {
  position: fixed;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: #fff; }

.header__wrap {
  padding: 15px 0px 15px 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header__logo {
  cursor: pointer; }

.header__menu {
  margin-left: 100px; }

.header__mail {
  margin-left: auto;
  margin-right: 45px; }

.header__mail .icon {
  width: 14px;
  height: 10px;
  margin-right: 10px; }

.header__phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-weight: 900;
  font-size: 1.125rem;
  margin-bottom: 10px; }

.header__phone span {
  font-size: 1.5rem;
  display: block;
  margin-left: 5px; }

.header__graphic {
  font-size: 0.9375rem; }

.header__burger {
  cursor: pointer;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header__burger .icon {
  width: 64px;
  height: 60px; }

.header__mobile-menu {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem; }

/* Стили секции header */
/* Стили секции top-banner */
.top-banner {
  padding-top: 130px;
  margin-bottom: 40px; }

.top-banner__wrap {
  max-width: 1090px;
  margin: 0 auto; }

.top-banner__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 43px;
  margin-bottom: 105px; }

.top-banner__logo {
  margin-right: 122px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none; }

.top-banner__title {
  font-size: 4rem;
  line-height: 3.75rem;
  font-weight: 900; }

.top-banner__title span {
  display: block; }

.top-banner__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.top-banner__item {
  max-width: 344px; }

.top-banner__item:nth-child(1) {
  max-width: 315px; }

.top-banner__item:nth-child(2) {
  max-width: 290px; }

.top-banner__item:nth-child(2) .top-banner__name {
  margin-bottom: 22px; }

.top-banner__name {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 15px; }

.top-banner__text {
  line-height: 1.125rem;
  margin-bottom: 10px; }

.top-banner__cost {
  font-weight: 900;
  letter-spacing: 0.01563rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px; }

.top-banner__cost span {
  font-size: 1.5rem;
  margin: 0 5px; }

.top-banner__cost:after {
  content: "*";
  display: block;
  font-size: 1.5rem;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-left: 2px; }

.top-banner__btn {
  max-width: 190px; }

/* Стили секции top-banner */
/* Стили секции top-form */
.top-form {
  margin-bottom: 90px; }

.top-form__wrap {
  background: #83599c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 20px 38px; }

.top-form__title {
  text-align: right;
  font-weight: 700;
  margin-right: 22px;
  max-width: 274px;
  width: 100%;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.25rem; }

.top-form__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.top-form__group {
  position: relative;
  width: 100%;
  max-width: 300px; }

.top-form__group + .top-form__group {
  margin-left: 20px; }

.top-form__input {
  padding-left: 14px;
  height: 40px;
  color: #3d3d3d;
  font-size: 0.875rem;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #9da7ad; }

.top-form__input:focus {
  border-color: #1792e7; }

.top-form__input:focus + .top-form__label {
  opacity: 0; }

.top-form__label {
  color: #6a6a6a;
  font-size: 0.875rem;
  position: absolute;
  left: 14px;
  top: 14px; }

.top-form__btn {
  display: block;
  margin-left: 15px;
  border: 2px solid #9da7ad;
  background: #fff;
  color: #402e20;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 52px 8px;
  cursor: pointer;
  border-radius: 4px; }

.top-form__btn:hover,
.top-form__btn:focus {
  border-color: #1792e7; }

/* Стили секции top-form */
/* Стили секции advantage */
.advantage {
  margin-bottom: 50px; }

.advantage__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.advantage__item {
  max-width: 222px; }

.advantage__icon {
  height: 128px;
  margin-bottom: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.advantage__text {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 900;
  text-transform: uppercase; }

.advantage__text span.red {
  display: block; }

.advantage__text span.nowrap {
  display: inline-block; }

/* Стили секции advantage */
/* Стили секции package */
.package {
  margin-bottom: 60px; }

.package__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #fff;
  font-size: 1.875rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 60px; }

.package__title span {
  background: #83599c;
  display: block;
  padding: 0 30px; }

.package__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 30px; }

.package__item {
  max-width: 340px;
  color: #2a2b2b; }

.package__subname {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding-bottom: 14px;
  padding-right: 80px;
  border-bottom: 6px solid #dc385a;
  margin-bottom: 15px;
  text-transform: uppercase; }

.package__name {
  display: block;
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 900;
  color: #1792e7;
  margin-bottom: 20px; }

.package__descr {
  margin-bottom: 40px; }

.package__text {
  font-size: 1rem;
  line-height: 1.125rem; }

.package__text + .package__text {
  margin-top: 20px; }

.package__cost {
  color: #3d3d3d;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 20px; }

.package__cost:after {
  content: "*";
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-left: 2px;
  font-size: 1.5rem; }

.package__cost span {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0 10px; }

.package__btn {
  display: block;
  max-width: 190px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  padding: 12px 43px 10px 46px;
  background: #dc385a; }

.package__btn:hover {
  background: #bc2141; }

/* Стили секции package */
/* Стили секции selection */
.selection {
  padding: 75px 0 60px;
  background: #f5f5f5;
  color: #2a2b2b;
  margin-bottom: 65px; }

.selection__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.selection__info {
  text-align: right;
  max-width: 325px;
  margin-right: 25px; }

.selection__pretitle {
  display: inline-block;
  padding-bottom: 16px;
  border-bottom: 6px solid #dc385a;
  margin-bottom: 15px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase; }

.selection__title {
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 900;
  color: #1792e7;
  margin-bottom: 40px; }

.selection__subtitle {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 25px; }

.selection__text {
  max-width: 310px;
  margin-left: auto;
  font-size: 1rem;
  line-height: 1.125rem; }

.selection__descr {
  margin-left: 25px; }

.selection__call,
.selection__fill {
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  margin-bottom: 15px; }

.selection__phone {
  display: block;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 2.5625rem;
  color: #3d3d3d; }

.selection__phone span {
  font-size: 3.4375rem;
  display: inline-block;
  margin-left: 10px; }

.selection__form {
  max-width: 430px; }

.selection__group {
  position: relative;
  width: 100%; }

.selection__group + .selection__group {
  margin-top: 10px; }

.selection__input {
  height: 70px;
  padding-left: 30px;
  width: 100%;
  border: 2px solid #9da7ad;
  border-radius: 5px;
  font-size: 0.875rem; }

.selection__input:focus {
  border-color: #1792e7; }

.selection__input:focus + .selection__label {
  opacity: 0; }

.selection__label {
  position: absolute;
  left: 30px;
  top: 28px;
  color: #6a6a6a;
  font-size: 0.875rem; }

.selection__btn {
  background: #1792e7;
  color: #fff;
  max-width: 100%;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 11px 95px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px; }

.selection__btn:hover,
.selection__btn:focus {
  background: #1275b9; }

.selection__btn span {
  display: block; }

/* Стили секции selection */
/* Стили секции services */
.services {
  margin-bottom: 65px; }

.services__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.services__item {
  max-width: 270px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.125rem; }

.services__image {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px; }

.services__image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.services__image:hover .services__order {
  opacity: 1;
  visibility: visible; }

.services__order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background: rgba(220, 56, 90, 0.88);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden; }

.services__name {
  text-transform: uppercase;
  font-weight: 900; }

.srevices__cost {
  display: block;
  color: #dc385a;
  font-weight: 900; }

.services__descr {
  max-width: 570px;
  color: #2a2b2b;
  position: relative;
  width: 100%; }

.services__el {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden; }

.services__el--active {
  opacity: 1;
  visibility: visible; }

.services__subname {
  display: block;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  max-width: 291px;
  margin-bottom: 15px;
  text-transform: uppercase; }

.services__text {
  font-size: 1rem;
  line-height: 1.25rem; }

/* Стили секции services */
/* Стили секции preparation */
.preparation {
  padding-top: 55px;
  padding-bottom: 85px;
  background: #1792e7;
  color: #fff; }

.preparation__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-left: 22px; }

.preparation__title span {
  padding: 8px 20px 8px 15px;
  background: #dc385a;
  display: block; }

.preparation__subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 22px;
  color: #2a2b2b;
  margin-bottom: 90px; }

.preparation__subtitle span {
  padding: 0px 20px;
  background: #fed300;
  display: block; }

.preparation__content {
  position: relative; }

.preparation__road {
  background: url("../img/content/preparation/road.png") no-repeat;
  max-width: 875px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 66px;
  padding-left: 29px;
  padding-top: 22px; }

.preparation__item {
  width: 100%;
  max-width: 260px; }

.preparation__item:nth-child(2) {
  margin-bottom: 80px; }

.preparation__item:nth-child(4) {
  margin-bottom: 60px; }

.preparation__item:nth-child(7) {
  margin-bottom: 50px; }

.preparation__item:nth-child(10) {
  margin-left: auto;
  margin-right: 22px; }

.preparation__item:nth-child(10),
.preparation__item:nth-child(11) {
  max-width: 285px; }

.preparation__item:nth-child(10) .preparation__text,
.preparation__item:nth-child(11) .preparation__text {
  padding-left: 85px; }

.preparation__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px; }

.preparation__num {
  display: block;
  font-size: 3.75rem;
  line-height: 2.75rem;
  font-weight: 700;
  margin-right: 10px;
  padding-top: 2px; }

.preparation__name {
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-weight: 700;
  text-transform: uppercase; }

.preparation__text {
  font-size: 0.875rem;
  line-height: 1rem;
  padding-left: 50px; }

.preparation__form-wrap {
  position: absolute;
  width: 100%;
  max-width: 340px;
  padding: 26px 48px 50px;
  background: #fff;
  top: -112px;
  right: 0; }

.preparation__form-title {
  font-size: 2.375rem;
  line-height: 2.0625rem;
  font-weight: 900;
  color: #1792e7;
  margin-bottom: 25px; }

.preparation__form-subtitle {
  display: block;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 900;
  margin-bottom: 25px;
  color: #3d3d3d; }

.preparation__group {
  position: relative;
  width: 100%; }

.preparation__group + .preparation__group {
  margin-top: 15px; }

.preparation__input {
  height: 42px;
  padding-left: 18px;
  font-size: 0.875rem;
  width: 100%;
  border: 2px solid #9da7ad;
  border-radius: 5px; }

.preparation__input:focus {
  border-color: #1792e7; }

.preparation__input:focus + .preparation__label {
  opacity: 0; }

.preparation__label {
  position: absolute;
  left: 18px;
  top: 15px;
  color: #6a6a6a;
  font-size: 0.875rem; }

.preparation__btn {
  background: #1792e7;
  color: #fff;
  font-size: 1rem;
  font-weight: 900;
  padding: 20px 42px;
  display: block;
  margin-top: 15px;
  cursor: pointer; }

.preparation__btn:hover {
  background: #1275b9; }

/* Стили секции preparation */
/* Стили секции client */
.client {
  padding-top: 68px;
  margin-bottom: 65px; }

.client__title {
  text-align: center;
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 900;
  margin-bottom: 75px; }

.client__title span {
  display: block; }

.client__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.client__item {
  margin-right: 20px;
  margin-bottom: 25px;
  max-width: 140px; }

.client__item img {
  width: 100%; }

/* Стили секции client */
/* Стили секции reviews */
.reviews {
  margin-bottom: 90px; }

.reviews__title {
  text-align: center;
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 900;
  margin-bottom: 75px; }

.reviews__title span {
  display: block; }

.reviews__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.reviews__item {
  max-width: 195px;
  text-align: center; }

.reviews__item:nth-child(2),
.reviews__item:nth-child(3) {
  max-width: 245px; }

.reviews__image {
  margin-bottom: 30px;
  cursor: pointer; }

.reviews__text {
  font-size: 1.125rem; }

.reviews__text2 {
  font-size: 12px;
  text-align: center; }

.reviews__popup {
  padding: 0; }

/* Стили секции reviews */
/* Стили секции consult */
.consult {
  margin-bottom: 22px; }

.consult__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.consult__info {
  padding: 32px 20px 84px 0px;
  max-width: 365px;
  width: 100%;
  margin-right: 10px;
  background: #f5f5f5;
  text-align: right;
  text-transform: uppercase;
  color: #2a2b2b; }

.consult__logo {
  margin-bottom: 50px; }

.consult__name {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 20px; }

.consult__address {
  font-size: 1rem;
  line-height: 1.125rem;
  max-width: 196px;
  margin-left: auto; }

.consult__phone {
  display: block;
  font-size: 3.3125rem;
  font-weight: 900;
  margin-bottom: 10px; }

.consult__phone:hover {
  color: #313131; }

.consult__mail {
  font-size: 2.0625rem; }

.consult__mail:hover {
  color: #313131; }

.consult__info-mobile {
  margin-bottom: 100px;
  display: none; }

.consult__descr {
  margin-left: 10px;
  max-width: 430px;
  width: 100%; }

.consult__pretitle {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #2a2b2b;
  position: relative;
  display: block;
  padding-bottom: 15px;
  margin-bottom: 15px; }

.consult__pretitle:after {
  content: '';
  display: block;
  width: 155px;
  height: 6px;
  background: #dc385a;
  position: absolute;
  bottom: 0;
  left: 0; }

.consult__title {
  font-weight: 900;
  color: #1792e7;
  font-size: 3rem;
  line-height: 2.75rem;
  margin-bottom: 35px; }

.consult__group {
  position: relative;
  width: 100%; }

.consult__group + .consult__group {
  margin-top: 15px; }

.consult__input,
.consult__textarea {
  height: 70px;
  padding-left: 30px;
  width: 100%;
  border: 2px solid #9da7ad;
  border-radius: 5px; }

.consult__input:focus,
.consult__textarea:focus {
  border-color: #1792e7; }

.consult__input:focus + .consult__label,
.consult__textarea:focus + .consult__label {
  opacity: 0; }

.consult__textarea {
  height: 220px;
  padding-top: 25px; }

.consult__label {
  position: absolute;
  left: 30px;
  top: 28px;
  color: #6a6a6a;
  font-size: 0.875rem; }

.consult__btn {
  background: #1792e7;
  color: #fff;
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 25px 0;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px; }

.consult__btn:hover,
.consult__btn:focus {
  background: #1275b9; }

/* Стили секции consult */
/* Стили секции footer */
.footer {
  position: relative; }

.footer__info {
  position: absolute;
  width: 100%;
  max-width: 830px;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 19px 80px 20px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3); }

.footer__contact {
  margin-right: 110px;
  width: 100%;
  max-width: 245px;
  min-width: 245px; }

.footer__phone {
  font-size: 1.5rem;
  font-weight: 900;
  display: block;
  margin-bottom: 5px; }

.footer__phone:hover {
  color: #313131; }

.footer__mail {
  font-size: 1rem; }

.footer__mail:hover {
  color: #313131; }

.footer__address {
  font-size: 1.5rem;
  font-weight: 900;
  color: #3d3d3d; }

.footer__address span {
  display: block;
  font-weight: 400;
  font-size: 0.9375rem;
  letter-spacing: 0.01563rem; }

/* Стили секции footer */
.btn {
  display: block;
  background: #dc385a;
  color: #fff;
  font-weight: 700;
  padding: 14px 55px 12px;
  text-transform: uppercase;
  border-radius: 5px; }

.btn:hover,
.btn:focus {
  background: #bc2141; }

.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.menu__item + .menu__item {
  margin-left: 50px; }

.menu__link {
  display: block;
  padding: 5px 0px;
  font-size: 0.875rem;
  text-transform: uppercase;
  border-bottom: 1px dotted #999; }

.menu__link:hover {
  border-bottom-color: transparent; }

.form .invalid input,
.form .invalid textarea {
  border-color: #dc385a; }

.form .valid input,
.form .valid textarea {
  border-color: #1792e7; }

.label.hide {
  opacity: 0; }

.order {
  padding: 20px 25px 25px 20px;
  background: #f5f5f5;
  text-align: left;
  max-width: 480px; }

.order__close {
  font-size: 0;
  left: 85%;
  top: 25px;
  width: 40px;
  height: 40px;
  z-index: 2;
  background: url("../img/general/close.png") center no-repeat;
  background-size: cover; }

.order__close:before {
  display: none; }

.order__subtitle {
  display: block;
  margin-bottom: 10px;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #2a2b2b;
  text-transform: uppercase;
  padding-bottom: 14px;
  position: relative; }

.order__subtitle:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 155px;
  height: 8px;
  background: #dc385a; }

.order__title {
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 45px;
  color: #1792e7; }

.order__group {
  position: relative;
  width: 100%; }

.order__group:first-child {
  visibility: hidden; }

.order__group + .order__group {
  margin-top: 15px; }

.order__group .order__input {
  padding-left: 30px;
  height: 68px;
  color: #3d3d3d;
  font-size: 0.875rem;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #9da7ad; }

.order__input:focus {
  border-color: #1792e7; }

.order__input:focus + .order__label {
  opacity: 0; }

.order__label {
  color: #6a6a6a;
  font-size: 0.875rem;
  position: absolute;
  left: 30px;
  top: 25px; }

.order__btn {
  background: #1792e7;
  color: #fff;
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 12px 0px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px; }

.order__btn:hover,
.order__btn:focus {
  background: #1275b9; }

.mobile {
  width: 100%;
  max-width: 100%;
  z-index: 10;
  background: #fff;
  padding: 0;
  padding-top: 40px;
  margin-bottom: 0; }

.mobile__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 55px;
  padding: 0 20px; }

.mobile__contact {
  width: 100%;
  max-width: 65%; }

.mobile__phone {
  display: block;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin-bottom: 25px;
  text-align: right; }

.mobile__phone:hover {
  color: #313131; }

.mobile__address {
  text-align: right;
  font-size: 1.5rem;
  line-height: 1.75rem; }

.mobile__address span {
  display: block; }

.mobile__info {
  background: #f5f5f5;
  padding: 20px 0 30px; }

.mobile__wrap {
  padding: 0 20px; }

.mobile__title {
  font-size: 3.125rem;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 470px;
  margin: 0 auto 15px;
  text-align: left; }

.mobile__title span {
  display: block; }

.mobile__subtitle {
  display: block;
  max-width: 470px;
  margin: 0 auto;
  font-size: 2.125rem;
  line-height: 2.25rem;
  text-align: left; }

.mobile__content {
  padding: 45px 20px; }

.mobile__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1.875rem;
  color: #fff;
  text-transform: uppercase;
  background: #83599c;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  height: 90px; }

.mobile__btn + .mobile__btn {
  margin-top: 30px; }

.mobile__btn:hover,
.mobile__btn:focus {
  background: #69477d; }

.mobile__btn span {
  display: block; }

.mobile__link {
  display: block;
  margin: 50px auto 0;
  text-transform: uppercase;
  font-size: 1.875rem;
  line-height: 1.875rem;
  text-decoration: underline;
  color: #83599c;
  background: none;
  cursor: pointer; }

.mobile__link:hover {
  text-decoration: none; }

@media (max-width: 1200px) {
  .container {
    padding: 0 10px; } }

@media (max-width: 1100px) {
  .header__wrap {
    padding-left: 0; }
  .header__menu {
    margin-left: 40px; }
  .top-banner__heading {
    padding-left: 0; }
  .top-banner__logo {
    margin-right: 50px; }
  .top-banner__item {
    max-width: 270px; }
  .top-form__wrap {
    min-width: calc(100% + 20px);
    margin-left: -10px; }
  .advantage__wrap {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .advantage__item {
    width: 100%;
    max-width: 49%;
    margin-bottom: 65px; }
  .advantage__icon {
    margin-bottom: 25px; }
  .advantage__text {
    max-width: 280px;
    margin: 0 auto; }
  .services__wrap {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .services__descr {
    display: none; }
  .preparation__subtitle {
    margin-bottom: 70px; }
  .preparation__road {
    background: none;
    padding: 0; }
  .preparation__item {
    max-width: 32%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .preparation__item:nth-child(6) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .preparation__item:nth-child(5) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .preparation__item:nth-child(4) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .preparation__item:nth-child(7),
  .preparation__item:nth-child(8),
  .preparation__item:nth-child(9) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .preparation__item:nth-child(11) {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .preparation__item:nth-child(10) {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
    margin-right: auto;
    margin-left: 30px; }
  .preparation__head {
    margin-bottom: 15px; }
  .preparation__text {
    font-size: 1rem; }
  .preparation__form-wrap {
    display: none; }
  .client__list {
    -ms-flex-pack: distribute;
    justify-content: space-around; } }

@media (max-width: 960px) {
  html,
  body {
    font-size: 0.75rem; }
  .header__menu {
    margin-left: 20px; }
  .header__mail {
    margin-right: 20px; }
  .header__mail a {
    font-size: 1rem; }
  .top-banner__heading {
    margin-bottom: 50px; }
  .top-banner__text {
    font-size: 1rem; }
  .top-form__wrap {
    display: block; }
  .top-form__title {
    margin-right: 0;
    margin-bottom: 40px;
    font-size: 2.75rem;
    line-height: 2.75rem;
    max-width: 100%; }
  .package__list {
    padding-left: 0; }
  .package__item {
    max-width: 33%; }
  .btn {
    font-size: 1.125rem; } }

@media (max-width: 768px) {
  .header__wrap {
    padding-left: 60px;
    padding-right: 25px; }
  .header__menu {
    display: none; }
  .header__mail .icon {
    margin-right: 5px; }
  .header__mail {
    display: none; }
  .header__info {
    display: none; }
  .header__burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .top-banner {
    padding-top: 110px; }
  .top-banner__heading {
    background: #f5f5f5;
    padding: 40px 0 35px 10px;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 40px; }
  .top-banner__logo {
    display: none; }
  .top-banner__title {
    font-size: 3.625rem; }
  .top-banner__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 520px;
    margin: 0 auto; }
  .top-banner__item {
    max-width: 100%; }
  .top-banner__item + .top-banner__item {
    margin-top: 60px; }
  .top-banner__item:nth-child(1),
  .top-banner__item:nth-child(2) {
    max-width: 100%; }
  .top-banner__name {
    font-size: 2.125rem;
    line-height: 2.125rem; }
  .top-banner__text {
    font-size: 1.875rem;
    line-height: 1.875rem; }
  .top-banner__cost {
    font-size: 1.5rem; }
  .top-banner__cost span {
    font-size: 1.875rem; }
  .top-banner__btn {
    max-width: 290px;
    margin: 0 auto;
    text-align: center; }
  .top-form__title {
    margin-bottom: 70px; }
  .top-form__form {
    display: block; }
  .top-form__group {
    max-width: 700px; }
  .top-form__group + .top-form__group {
    margin-left: 0;
    margin-top: 55px; }
  .top-form__input {
    height: 86px;
    font-size: 1.875rem; }
  .top-form__label {
    font-size: 1.875rem;
    top: 32px; }
  .top-form__btn {
    margin-left: auto;
    margin-top: 50px;
    background: #dc385a;
    border: none;
    color: #fff;
    font-size: 2.125rem;
    padding: 30px 40px; }
  .top-form__btn:hover,
  .top-form__btn:focus {
    border-color: #dc385a;
    background: #bc2141; }
  .advantage__text {
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-weight: 400; }
  .package {
    display: none; }
  .selection {
    padding: 50px 0;
    margin-bottom: 30px; }
  .selection__wrap {
    display: block;
    max-width: 430px;
    margin: 0 auto; }
  .selection__info {
    max-width: 100%;
    margin-right: 0;
    text-align: left;
    margin-bottom: 50px; }
  .selection__title {
    margin-bottom: 25px;
    max-width: 170px;
    font-size: 2.75rem; }
  .selection__subtitle {
    font-size: 1.875rem;
    line-height: 2.125rem;
    margin-bottom: 35px;
    max-width: 360px; }
  .selection__text {
    max-width: 340px;
    margin-left: 0;
    font-size: 1.5rem;
    line-height: 1.625rem; }
  .selection__descr {
    margin-left: 0; }
  .selection__call,
  .selection__fill {
    display: none; }
  .selection__phone {
    display: none; }
  .selection__group + .selection__group {
    margin-top: 55px; }
  .selection__input {
    height: 90px;
    font-size: 1.875rem; }
  .selection__label {
    font-size: 1.875rem;
    top: 32px; }
  .selection__btn {
    font-size: 2.125rem;
    line-height: 2.875rem;
    margin-top: 55px;
    padding: 30px 0; }
  .services {
    margin-bottom: 45px; }
  .services__item {
    max-width: 190px; }
  .services__name {
    font-size: 1rem; }
  .srevices__cost {
    font-size: 1.125rem;
    margin-top: 5px; }
  .preparation {
    display: none; }
  .client {
    padding-top: 0;
    margin-bottom: 40px; }
  .client__title {
    margin-bottom: 60px; }
  .client__item {
    max-width: 125px;
    margin-bottom: 15px;
    margin-right: 10px; }
  .reviews {
    margin-bottom: 50px; }
  .reviews__title {
    margin-bottom: 60px; }
  .reviews__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .reviews__item {
    width: 100%;
    max-width: 50%;
    margin-bottom: 20px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .reviews__item:nth-child(2),
  .reviews__item:nth-child(3) {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .reviews__image {
    margin-bottom: 15px; }
  .consult__info {
    display: none; }
  .consult__name {
    font-size: 1.875rem;
    line-height: 2.125rem;
    margin-bottom: 10px; }
  .consult__address {
    margin-left: 0;
    max-width: 100%;
    font-size: 1.875rem;
    line-height: 2.125rem;
    margin-bottom: 25px; }
  .consult__info-mobile {
    display: block; }
  .consult__descr {
    margin-left: 0; }
  .consult__title {
    margin-bottom: 25px; }
  .consult__group + .consult__group {
    margin-top: 40px; }
  .consult__input,
  .consult__textarea {
    font-size: 1.875rem;
    height: 90px; }
  .consult__textarea {
    height: 330px; }
  .consult__label {
    font-size: 1.875rem; }
  .consult__btn {
    margin-top: 50px;
    font-size: 2.125rem; }
  .footer {
    display: none; } }

@media (max-width: 480px) {
  .header__wrap {
    padding: 10px 0; }
  .header__burger .icon {
    width: 34px;
    height: 30px; }
  .header__mobile-menu {
    font-size: 1.25rem; }
  .top-banner {
    padding-top: 70px;
    margin-bottom: 30px; }
  .top-banner__heading {
    margin-bottom: 20px;
    padding-bottom: 25px;
    padding-top: 30px; }
  .top-banner__title {
    font-size: 2.25rem;
    line-height: 2.5rem; }
  .top-banner__item + .top-banner__item {
    margin-top: 50px; }
  .top-banner__name {
    font-size: 1.375rem;
    line-height: 1.5rem; }
  .top-banner__text {
    font-size: 1.1875rem;
    line-height: 1.375rem; }
  .top-banner__btn {
    max-width: 240px; }
  .top-form {
    margin-bottom: 50px; }
  .top-form__wrap {
    padding: 30px 20px 25px; }
  .top-form__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 40px;
    max-width: 247px;
    margin-left: auto; }
  .top-form__group + .top-form__group {
    margin-top: 20px; }
  .top-form__input {
    height: 50px;
    font-size: 1.5rem; }
  .top-form__label {
    font-size: 1.5rem;
    top: 15px; }
  .top-form__btn {
    margin-top: 20px;
    font-size: 1.375rem;
    padding: 15px 20px; }
  .advantage {
    margin-bottom: 30px; }
  .advantage__item {
    margin-bottom: 40px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .advantage__item:nth-child(3) .advantage__icon {
    max-width: 50%; }
  .advantage__item:last-child .advantage__icon {
    max-width: 30%; }
  .advantage__icon {
    max-width: 40%;
    margin: 0 auto 15px;
    height: 90px; }
  .advantage__text {
    font-size: 1rem;
    line-height: 1.25rem; }
  .selection {
    padding: 30px 0;
    margin-bottom: 20px; }
  .selection__info {
    margin-bottom: 20px; }
  .selection__pretitle {
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding-bottom: 10px;
    border-bottom-width: 3px;
    margin-bottom: 10px; }
  .selection__title {
    font-size: 2.125rem;
    line-height: 2.125rem;
    margin-bottom: 20px; }
  .selection__subtitle {
    font-size: 1.25rem;
    line-height: 1.375rem;
    margin-bottom: 10px; }
  .selection__text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 100%; }
  .selection__group + .selection__group {
    margin-top: 20px; }
  .selection__input {
    height: 50px;
    font-size: 1.125rem;
    padding-left: 20px; }
  .selection__label {
    font-size: 1.5rem;
    top: 15px;
    left: 20px; }
  .selection__btn {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 25px;
    padding: 15px 0;
    width: 100%; }
  .services {
    margin-bottom: 30px; }
  .services__item {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    max-width: 45%; }
  .services__image {
    margin-bottom: 15px; }
  .services__name {
    font-size: 1rem; }
  .srevices__cost {
    font-size: 1rem; }
  .client {
    margin-bottom: 25px; }
  .client__title {
    margin-bottom: 40px;
    font-size: 2.5rem;
    line-height: 2.625rem; }
  .client__item {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
    max-width: 33%;
    margin-right: 0; }
  .reviews {
    margin-bottom: 30px; }
  .reviews__title {
    font-size: 2.25rem;
    line-height: 2.375rem;
    margin-bottom: 30px; }
  .reviews__image {
    max-width: 80%;
    margin: 0 auto 15px; }
  .consult__name {
    font-size: 1.75rem;
    line-height: 1.875rem; }
  .consult__address {
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-bottom: 15px; }
  .consult__phone {
    font-size: 2.125rem; }
  .consult__mail {
    font-size: 1.75rem; }
  .consult__info-mobile {
    margin-bottom: 30px; }
  .consult__pretitle {
    font-size: 1.125rem;
    line-height: 1.25rem;
    padding-bottom: 10px;
    margin-bottom: 10px; }
  .consult__pretitle:after {
    width: 100px;
    height: 4px; }
  .consult__title {
    font-size: 2.125rem;
    line-height: 2.375rem;
    margin-bottom: 20px; }
  .consult__group + .consult__group {
    margin-top: 20px; }
  .consult__input,
  .consult__textarea {
    font-size: 0.875rem;
    height: 50px;
    padding-left: 15px; }
  .consult__textarea {
    height: 120px;
    padding-top: 20px; }
  .consult__label {
    font-size: 1.5rem;
    left: 15px;
    top: 15px; }
  .consult__btn {
    margin-top: 20px;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 20px 0; }
  .order__close {
    width: 25px;
    height: 25px;
    left: 83%; }
  .order__subtitle {
    font-size: 1.25rem;
    line-height: 1.375rem;
    padding-bottom: 10px; }
  .order__subtitle:after {
    width: 100px;
    height: 4px; }
  .order__title {
    font-size: 2.125rem;
    line-height: 2.25rem;
    margin-bottom: 20px;
    max-width: 220px; }
  .order__input {
    height: 50px;
    padding-left: 15px;
    font-size: 1rem; }
  .order__label {
    left: 15px;
    font-size: 1.5rem;
    top: 15px; }
  .order__btn {
    margin-top: 20px;
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding: 18px 0; }
  .mobile {
    padding-top: 20px;
    vertical-align: top;
    min-height: 100%; }
  .mobile__header {
    margin-bottom: 20px; }
  .mobile__logo {
    max-width: 40%; }
  .mobile__logo img {
    width: 100%; }
  .mobile__contact {
    max-width: 100%; }
  .mobile__phone {
    font-size: 1.125rem;
    margin-bottom: 10px; }
  .mobile__address {
    font-size: 0.875rem;
    line-height: 1rem; }
  .mobile__info {
    padding: 15px 0 10px; }
  .mobile__title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    max-width: 215px;
    margin-left: 0; }
  .mobile__subtitle {
    font-size: 0.875rem;
    line-height: 1.125rem;
    max-width: 215px;
    margin-left: 0; }
  .mobile__content {
    padding: 15px; }
  .mobile__btn {
    font-size: 1rem;
    line-height: 1.125rem;
    height: 50px; }
  .mobile__btn + .mobile__btn {
    margin-top: 15px; }
  .mobile__link {
    margin-top: 20px;
    font-size: 1.125rem; } }
