/**
  * Flat Contact
  * Flat Qoute
  * Flat Imagebox
  * Flat Row Title
  * Flat Iconbox
  * Flat Iconbox Style1
  * Flat Iconbox Style2
  * Flat Iconbox Style3
  * Flat Iconbox Style4
  * Flat Form Qoute
  * Flat Imagebox Style2
  * Flat Imagebox Style3
  * Flat Testimonial
  * Flat Testimonial Style2
  * Flat Accordion
  * Flat Counter
  * Flat Form Request
  * Header Style2
  * Header Style3
  * Header Style4
  * Flat Couter Style2
  * Flat Introduce
  * Flat Branch
  * Main About
  * Flat Team
  * Testimonial Item Style3
  * Imagebox
  * Imagebox Item Style1
  * Flat Form Quote
  * Parallax
*/
/* Flat Contact
-------------------------------------------------------------- */
.flat-contact {
  padding: 80px 0 40px; }

/* Contact Item */
.height35 {
  height: 35px; }

.contact-item h4 {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px; }

.contact-item p {
  margin-bottom: 0; }

/* Contact Form */
.contact-form {
  margin: 0 -10px;
  margin-bottom: 30px; }

.contact-form #contactform .flat-one-half div,
.contact-form #contactform .flat-one-half {
  position: relative; }

.contact-form #contactform .flat-one-half textarea {
  height: 288px;
  padding: 15px 0 0 15px; }

.contact-form #contactform .flat-one-half .input-submit button[type="submit"] {
  width: 100%;
  text-transform: uppercase;
  font-family: "Karla", sans-serif;
  letter-spacing: 2px;
  font-size: 13px; }

label.error {
  color: red;
  position: absolute;
  left: 0;
  top: -25px; }

.contact-form #contactform .flat-one-half > label.error {
  left: 10px; }

/* pdmap */
.pdmap {
  padding: 0 15px; }

/* Flat Qoute
-------------------------------------------------------------- */
.flat-qoute {
  background-color: #2691e4;
  padding-top: 22px;
  padding-bottom: 32px; }

.flat-qoute .text-qoute h3 {
  margin-top: 20px;
  color: #ffffff;
  font-weight: 600; }

.flat-qoute .btn-qoute {
  margin-top: 11px; }

.flat-qoute .btn-qoute button {
  font-weight: 600;
  background-color: #fff;
  border-color: #fff;
  color: #4b4b4b;
  border: 2px solid #fff;
  line-height: normal; }

.btn-qoute.right button:hover {
  color: #fff;
  border-color: #364c61;
  background-color: #364c61; }

/* Flat Imagebox
-------------------------------------------------------------- */
.flat-imagebox {
  padding: 70px 0 80px; }

/* Imagebox Style1 */
.imagebox.style1 {
  margin: 0; }

.imagebox.style1 .imagebox-header h3.imagebox-title {
  font-size: 18px;
  font-weight: 700;
  color: #222;
  margin-bottom: 9px;
  margin-top: 20px;
  letter-spacing: -0.2px; }

.imagebox.style1 .imagebox-content .imagebox-desc {
  margin-bottom: 14px; }

.imagebox.style1 .imagebox-content a.btn-imagebox {
  font-weight: 700;
  position: relative;
  padding-left: 13px;
  color: #2691e4;
  line-height: 24px;
  display: inline-block; }

.imagebox.style1 .imagebox-content a.btn-imagebox:before {
  font-family: "FontAwesome";
  content: "\f0da";
  font-size: 14px;
  color: #2691e4;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.imagebox.style1 .imagebox-content a.btn-imagebox:hover {
  padding-left: 20px; }

/* Flat Row Title
-------------------------------------------------------------- */
.flat-row-title {
  margin-bottom: 0px; }

.flat-row-title h2 {
  text-transform: uppercase;
  font-size: 1.758em;
  margin-bottom: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 0;
  position: relative;
  padding-bottom: 20px; }

.flat-row-title h2:after {
  position: absolute;
  left: 0;
  bottom: -0;
  width: 50px;
  height: 3px;
  content: "";
  background: #2691e4; }

.flat-row-title.center h2:after {
  left: 50%;
  margin-left: -25px; }

/* Flat Iconbox
-------------------------------------------------------------- */
.flat-iconbox {
  padding: 90px 0 90px;
  background-color: #f2f2f2; }

.height60 {
  height: 60px; }

/* Iconbox Style1 */
.iconbox .iconbox-icon {
  width: 60px;
  height: 60px;
  background: #2691e4;
  line-height: 60px;
  font-size: 28px;
  margin-right: 20px;
  margin-top: 4px;
  text-align: center;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.iconbox:hover .iconbox-icon {
  background-color: #364c61;
  transform: rotateY(180deg); }

.iconbox .iconbox-icon span {
  color: #ffffff; }

.iconbox .iconbox-content h3.iconbox-title {
  padding-left: 80px;
  margin-top: 0;
  font-size: 18px; }

.iconbox .iconbox-content .iconbox-desc {
  overflow: hidden; }

/* Flat Iconbox Style1
-------------------------------------------------------------- */
.flat-iconbox.style1 {
  background-color: #ffffff;
  padding: 80px 0; }

/* Flat Iconbox Style2
-------------------------------------------------------------- */
.flat-iconbox.style2 {
  background-color: #ffffff;
  padding: 80px 0; }

.flat-iconbox.style2 .flat-row-title h2:after {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: -15px;
  width: 100px;
  height: 2px;
  background: #18364a;
  content: ""; }

.flat-iconbox.style2 .flat-row-title h2:before {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: -15px;
  width: 50px;
  height: 2px;
  background: #2691e4;
  content: "";
  z-index: 99; }

.flat-iconbox.style2 .flat-row-title .desc-title {
  font-size: 18px;
  color: #848D91; }

.height70 {
  height: 70px; }

/* Iconbox Style2 */
.iconbox.style2 .iconbox-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 38px;
  line-height: 70px; }

/* Flat Iconbox Style3
-------------------------------------------------------------- */
.flat-iconbox.style3 {
  background-color: #fff;
  padding: 80px 0 100px; }

.height30 {
  height: 30px; }

/* Iconbox Style3 */
.iconbox.style3 {
  padding: 25px 15px 20px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 3px; }

.iconbox.style3 .iconbox-icon {
  background-color: transparent;
  line-height: 70px;
  width: 70px;
  height: 70px;
  font-size: 45px; }

.iconbox.style3 .iconbox-icon {
  margin-right: 10px;
  margin-top: 2px; }

.iconbox.style3 .iconbox-icon span {
  color: #2691e4; }

.iconbox.style3 .iconbox-content .iconbox-title {
  font-size: 18px;
  margin-top: 0;
  padding-left: 80px; }

/* Flat Iconbox Style4
-------------------------------------------------------------- */
.flat-iconbox.style4 {
  background-color: #fff; }

/* Iconbox Style4 */
.iconbox.style4 {
  text-align: center;
  border: 1px solid #e7e7e7;
  padding: 45px 35px 40px 35px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.iconbox.style4:hover {
  margin-top: -5px;
  box-shadow: 0 0 15px -5px #000; }

.iconbox.style4 .iconbox-icon {
  float: none;
  margin: 0;
  line-height: 54px;
  height: 50px;
  font-size: 50px;
  display: inline-block;
  margin-bottom: 40px;
  background-color: transparent; }

.iconbox.style4 .iconbox-icon span {
  color: #2691e4; }

.iconbox.style4 h3.iconbox-title {
  margin-bottom: 35px;
  margin-top: 0;
  font-size: 18px; }

.height45 {
  height: 45px; }

/* Flat Form Qoute
---------------------------------------------------------------*/
.flat-form-qoute {
  padding: 90px 0; }

/* Form Qoute */
.form-qoute {
  padding: 0 210px; }

.form-qoute .form-qoute-title {
  color: #fff;
  font-size: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
  padding-bottom: 30px;
  position: relative;
  font-weight: 600; }

.form-qoute .form-qoute-title:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background: #fff;
  margin-left: -30px; }

.form-qoute .desc-qoute {
  margin-bottom: 45px;
  padding: 0 100px;
  color: #cecece;
  text-align: center; }

.form-qoute p {
  margin-bottom: 0; }

.form-qoute p button {
  margin-top: 30px;
  background: #32485c; }

/* Flat Wrap Form */
.flat-wrap-form {
  margin: 0 -10px; }

.flat-wrap-form .flat-wrap-input {
  width: 50%;
  float: left;
  padding: 0 15px; }

.form-qoute .flat-wrap-form .flat-wrap-input input,
.form-qoute .flat-wrap-form .flat-wrap-input select {
  background: rgba(9, 27, 39, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #cecece; }

.form-qoute .flat-wrap-form .flat-wrap-input input:-moz-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input textarea:-moz-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input input::-moz-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input textarea::-moz-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input select {
  color: #cecece;
  opacity: 1; }

.form-qoute .flat-wrap-form .flat-wrap-input input:-ms-input-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input textarea:-ms-input-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input select {
  color: #cecece; }

.form-qoute .flat-wrap-form .flat-wrap-input input::-webkit-input-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input textarea::-webkit-input-placeholder,
.form-qoute .flat-wrap-form .flat-wrap-input select {
  color: #cecece;
  opacity: 1; }

/* Flat Imagebox Style2
---------------------------------------------------------------*/
.flat-imagebox.style2 {
  padding: 80px 0; }

/* News Post */
.news-post:last-of-type {
  margin-bottom: 35px; }

.news-post .featured-post {
  margin-bottom: 12px; }

.news-post .entry-post .entry-title h3 {
  font-size: 18px;
  margin-top: 9px;
  margin-bottom: 20px; }

.news-post .entry-post .content-post p {
  margin-bottom: 0; }

.news-post .entry-post .content-post .button-post a {
  display: inline-block;
  text-transform: none;
  border-radius: 3px;
  font-size: 14px;
  line-height: 49px;
  padding: 0 31px 0 31px;
  color: #fff;
  background-color: #2691e4;
  font-weight: 600;
  margin-top: 40px; }

.content-post {
  letter-spacing: 0.03px;
  padding: 0 0 10px 0; }

/* Flat Imagebox Style3
---------------------------------------------------------------*/
.flat-imagebox.style3 {
  padding: 80px 0; }

/* Imagebox Style3 */
.imagebox.style3 {
  margin: 0;
  text-align: center; }

.imagebox.style3 .imagebox-image {
  margin-bottom: 50px; }

.imagebox.style3 .imagebox-image a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center; }

.imagebox.style3 .imagebox-image a i {
  position: absolute;
  color: #364c61;
  font-size: 22px;
  z-index: 9;
  opacity: 0;
  top: 50%;
  left: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.imagebox.style3 .imagebox-image a:hover i {
  left: 50%;
  opacity: 1; }

.imagebox.style3 .imagebox-image a:hover i:hover {
  background-color: #364c61;
  color: #fff; }

.imagebox.style3 .imagebox-image a .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(54, 76, 97, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.imagebox.style3:hover .imagebox-image a .overlay {
  opacity: 1; }

.imagebox.style3 .imagebox-header h3.imagebox-title {
  position: relative;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 30px;
  font-size: 20px;
  letter-spacing: -0.2px; }

.imagebox.style3 .imagebox-header h3.imagebox-title:before {
  position: absolute;
  left: 50%;
  top: 0;
  width: 60px;
  height: 1px;
  background: #2691e4;
  content: "";
  margin-left: -30px; }

.imagebox.style3 .imagebox-content .imagebox-desc {
  margin-bottom: 14px; }

/* Flat Testimonial
-------------------------------------------------------------- */
.flat-testimonial {
  padding: 90px 0; }

.flat-testimonial .flat-row-title h2 {
  color: #ffffff; }

/* Testimonial Item */
.testimonial-item blockquote {
  background: transparent;
  text-align: center;
  color: #cecece;
  font-size: 18px;
  font-style: normal;
  color: #fff;
  line-height: 26px;
  padding-bottom: 20px;
  border-left: 0;
  padding: 0 220px;
  margin-bottom: 19px; }

.testimonial-item .testimonial-content {
  text-align: center;
  overflow: hidden; }

.testimonial-item .testimonial-content .testimonial-image {
  width: 120px;
  height: 120px;
  margin: 7px auto 26px;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden; }

.testimonial-item .testimonial-content .testimonial-author h6.name {
  font-weight: 600;
  color: #222;
  margin-bottom: 5px; }

.testimonial-item .testimonial-content .testimonial-author h6.name a {
  color: #cecece; }

.testimonial-item .testimonial-content .testimonial-author .author-info {
  font-size: 13px;
  color: #999; }

.testimonial-item .testimonial-content .testimonial-author .queue span {
  font-size: 14px;
  color: #fec42d;
  margin: 0 1px; }

/* Flat Testimonial Style2
-------------------------------------------------------------- */
.flat-testimonial.style2 {
  background-color: #f2f2f2; }

.flat-testimonial.style2 .flat-row-title h2 {
  color: #222; }

/* Testimonial style2 */
.testimonial-item.style2 blockquote {
  background-color: #fff;
  color: #656565;
  border-radius: 4px;
  padding: 30px 40px 30px;
  margin-bottom: 50px;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  z-index: 99; }

.testimonial-item.style2 blockquote:before {
  position: absolute;
  left: 30px;
  top: 30px;
  font-family: "Ionicons";
  content: "\f347";
  font-size: 50px;
  color: #f2f2f2;
  opacity: 0.6;
  z-index: -1; }

.testimonial-item.style2 blockquote:after {
  content: "";
  position: absolute;
  left: 40px;
  bottom: -17px;
  border-width: 0 18px 18px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent; }

.testimonial-item.style2 .testimonial-content {
  text-align: left; }

.testimonial-item.style2 .testimonial-content .testimonial-image {
  width: 80px;
  height: 80px;
  float: left;
  margin: 0;
  margin-right: 20px;
  border: none; }

.testimonial-item.style2 .testimonial-content .testimonial-author h6.name {
  margin-top: 10px; }

.testimonial-item.style2 .testimonial-content .testimonial-author h6.name a {
  color: #18364a; }

/* Flat Accordion
-------------------------------------------------------------- */
.flat-accordion {
  padding: 100px 0 0px; }

/* Text Box */
.text-box {
  padding-right: 35px; }

.text-box .title-box {
  margin-bottom: 25px; }

.text-box .title-box h4 {
  margin-bottom: 5px;
  font-size: 18px;
  color: #18364a;
  margin-top: 10px; }

.text-box .title-box h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.4em;
  color: #1c2125; }

.text-box .text p:last-child {
  margin-bottom: 0; }

.text-box .btn-box {
  margin-top: 25px; }

.btn-box a {
  display: inline-block;
  padding: 13px 30px;
  font-family: "Karla", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  background-color: #3a526a;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.text-box .btn-box a {
  background-color: #2691e4; }

.text-box .btn-box a:hover {
  color: #fff; }

/* Accordion */
.accordion .accordion-toggle {
  margin-bottom: 19.5px;
  font-size: 16px;
  border-radius: 5px;
  line-height: 24px;
  background-color: transparent; }

.accordion .accordion-toggle .toggle-title {
  display: block;
  padding: 22px 0 22px 46px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  color: #222;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  line-height: 1;
  margin: 0; }

.accordion .accordion-toggle .toggle-title:after {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 26px;
  right: 0;
  top: 49%;
  width: 12px;
  height: 2px;
  background-color: #222;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }

.accordion .accordion-toggle .toggle-title:before {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 25.5px;
  width: 2px;
  height: 12px;
  background-color: #222;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.accordion .accordion-toggle .toggle-title.active:before {
  width: 0px; }

.accordion .accordion-toggle .toggle-content {
  border: 1px solid #f0f0f0;
  border-top-color: transparent;
  padding: 13px 20px; }

.accordion .accordion-toggle .toggle-content p img {
  margin: 7px 30px 25px 0;
  display: inline;
  float: left; }

.accordion .accordion-toggle .toggle-content p {
  margin-bottom: 0; }

/* Flat Counter
---------------------------------------------------------------*/
.flat-counter {
  padding: 74px 0; }

/* Counter Title */
.counter-title h2 {
  font-size: 32px;
  color: #ffffff;
  line-height: 1.3;
  text-align: left;
  font-weight: 900;
  position: relative; }

.counter-title h2:after {
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 120px;
  height: 5px;
  background: #2691e4;
  content: ""; }

/* Counter Item */
.counter {
  margin-left: -15px;
  margin-right: -15px; }

.counter-item {
  width: 33.33%;
  float: left;
  padding-left: 15px;
  padding-right: 15px; }

.counter-item .counter-value .icon-wrap span {
  font-size: 40px;
  color: #2691e4;
  display: inline-block;
  margin-bottom: 20px; }

.counter-item .counter-value .numb-count {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 16px;
  display: inline-block;
  color: #fff; }

.counter-item .counter-content p {
  color: #bbbbbb; }

/* Flat Form Request
---------------------------------------------------------------*/
.flat-request-qoute {
  background-color: #f2f2f2;
  overflow: hidden; }

.flat-request-qoute .row {
  margin: 0; }

.flat-request-qoute .row .col-sm-6 {
  padding-right: 0px;
  padding-left: 0px; }

.form-request-qoute {
  padding: 75px 65px 75px 65px; }

.form-request-qoute .request-qoute-title {
  color: #222;
  font-family: 'Karla', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase; }

.form-request-qoute .desc-qoute {
  margin-bottom: 20px; }

.form-request-qoute .flat-wrap-form .flat-wrap-input {
  padding: 0 5px; }

.form-request-qoute .flat-wrap-form .flat-wrap-input input {
  margin-bottom: 10px; }

/* Header Style2
---------------------------------------------------------------*/
.header.style2 #logo {
  margin-top: 40px;
  margin-left: 0; }

/* Header Content */
.header-content {
  max-width: 870px;
  margin-top: 40px;
  float: right; }

.header-content ul {
  float: left;
  margin-left: 0;
  margin-bottom: 40px; }

.header-content ul li {
  float: left;
  list-style: none;
  width: 270px;
  margin-right: 30px;
  position: relative;
  padding-left: 39px;
  line-height: 20px; }

.header-content ul li:last-child {
  margin-right: 0; }

.header-content ul li i {
  font-size: 22px;
  position: absolute;
  left: 0;
  top: 2px; }

.header-content ul li strong {
  display: inline-block;
  letter-spacing: -0.1px;
  margin-bottom: 5px;
  color: #222; }

.header-content ul li p {
  margin-bottom: 0; }

/* Nav Header Style2 */
.nav.header-style2 {
  background: #18364a; }

.nav.header-style2 .nav-wrap {
  float: left; }

.nav.header-style2 .flat-social {
  margin-top: 18px;
  float: right;
  margin-right: -6px; }

.header-style2 #mainnav > ul > li:first-child {
  margin-left: 0; }

.header-style2 #mainnav > ul > li {
  margin-left: 47.5px; }

.header-style2 #mainnav > ul > li > a {
  line-height: 65px;
  color: #fff; }

.header-style2 #mainnav > ul > li.active > a:after,
.header-style2 #mainnav > ul > li > a:after {
  bottom: 20px; }

.nav.header-style2 ul.flat-social li {
  margin-left: 10.5px; }

.nav.header-style2 ul.flat-social li a {
  font-size: 16px; }

.nav.header-style2 ul.flat-social li:last-child a {
  margin-right: 4px; }

/* Header Style3
---------------------------------------------------------------*/
.header.style3 .header-wrap {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.header.style3 .header-wrap #logo {
  margin-top: 0; }

.header.style3 #mainnav > ul > li:first-child {
  margin-left: 46px; }

.header.style3 #mainnav > ul > li {
  margin-left: 37px; }

.header.style3 .header-wrap .nav-wrap #mainnav > ul > li > a {
  line-height: 90px; }

.header.style3 .header-wrap .header-content {
  margin-top: 0px; }

.header.style3 .header-wrap .header-content > * {
  margin-bottom: 0;
  float: left;
  margin-left: 0; }

.header.style3 .header-wrap .header-content img {
  margin-right: 20px;
  margin-top: 5px; }

.header.style3 .header-wrap .header-content div {
  overflow: hidden; }

.header.style3 .header-wrap .header-content div p {
  font-size: 12px;
  color: #656565;
  margin-bottom: 0; }

.header.style3 .header-wrap .header-content div strong {
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  letter-spacing: -0.1px;
  color: #222; }

/* Header Style4
---------------------------------------------------------------*/
.header.style4 #logo {
  margin-top: 29px;
  margin-left: 0; }

.header.style4 .header-content {
  margin-top: 31px; }

.header.style4 .header-content ul {
  margin-bottom: 0; }

/* Nav Header Style4 */
.nav.header-style4 #mainnav > ul > li.active > a {
  line-height: 70px; }

.nav.header-style4 {
  padding: 0 40px;
  position: relative;
  bottom: -35px;
  z-index: 99; }

.header-style4 #mainnav > ul > li {
  margin-left: 41.5px; }

.nav.header-style4 .flat-social {
  margin-top: 20px; }

/* Flat Couter Style2
---------------------------------------------------------------*/
.flat-counter.style2 {
  padding: 80px 0; }

/* Flat Text Box */
.flat-text-box h2 {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 25px;
  margin-top: 0px; }

.flat-text-box p {
  margin-bottom: 0; }

/* Counter Item */
.counter-item.style2 .counter-value .numb-count {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 16px;
  display: inline-block;
  color: #222222; }

.counter-item.style2 .counter-content p {
  color: #656565;
  margin-bottom: 20px; }

/* Flat Introduce
---------------------------------------------------------------*/
.flat-introduce {
  padding: 100px 0; }

.flat-introduce h1 {
  color: #fff;
  margin-bottom: 0;
  line-height: 1.1;
  padding: 0 180px; }

.flat-introduce h1 span {
  color: #2691e4; }

/* Flat Branch
---------------------------------------------------------------*/
.flat-branch {
  padding: 80px 0;
  background-color: #fcfcfc; }

/* Branch */
.branch {
  background-color: #fff;
  padding: 88px 40px 34px 40px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.branch .branch-icon {
  width: 60px;
  height: 60px;
  background-color: #2691e4;
  line-height: 60px;
  text-align: center;
  position: absolute;
  left: 40px;
  top: 0;
  color: #fff; }

.branch .branch-icon:before {
  position: absolute;
  left: 20px;
  top: 0;
  font-family: "FontAwesome";
  content: "\f0ac";
  color: #fff;
  font-size: 23px; }

.branch .branch-name {
  font-size: 17px;
  font-weight: 600;
  color: #222;
  margin-bottom: 15px; }

.branch p {
  font-weight: 400;
  color: #656565;
  margin-bottom: 5px; }

.branch ul li {
  position: relative;
  padding: 5px 0 5px 20px;
  font-weight: 300; }

.branch ul li:before {
  position: absolute;
  left: 0;
  top: 5px;
  font-family: "FontAwesome";
  content: "\f0ac"; }

.branch ul li.email:before {
  content: "\f0e0"; }

.branch ul li.phone:before {
  content: "\f095"; }

.branch:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px); }

/* Main About
---------------------------------------------------------------*/
.main-about.page-wrap .content-area {
  float: right;
  padding-right: 0; }

.main-about.page-wrap .widget-area {
  float: left;
  padding-right: 70px;
  padding-left: 0px; }

.main-about .sidebar .widget {
  margin-bottom: 27px; }

.main-about .sidebar .widget-title {
  letter-spacing: -0.5px; }

.main-about .sidebar .widget-text p {
  margin-bottom: 23px;
  letter-spacing: -0.5px; }

.main-about .sidebar .widget-text .textwidget {
  margin-bottom: 2px;
  margin-top: 7px; }

.main-about .sidebar .widget-text .textwidget a {
  display: inline-block;
  padding: 11px 18px 9px 17px;
  font-family: "Karla", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  background-color: #dc291e;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 130px;
  text-align: center;
  letter-spacing: 0.4px;
  border-radius: 3px;
  padding-top: 8px;
  background-color: #2691e4; }

/* Sidebar Main About */
.sidebar .widget.widget-nav-menu ul li {
  display: block;
  padding: 0 0 0 25px;
  margin-bottom: 20px;
  color: #151515;
  font-weight: 700;
  background-color: #efefef;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.sidebar .widget.widget-nav-menu ul li a {
  padding-top: 13px;
  padding-bottom: 13px;
  display: block;
  position: relative;
  z-index: 10; }

.sidebar .widget.widget-nav-menu ul li:hover,
.sidebar .widget.widget-nav-menu ul li.active {
  background-color: #2691e4; }

.sidebar .widget.widget-nav-menu ul li:hover a,
.sidebar .widget.widget-nav-menu ul li.active a {
  color: #fff; }

.sidebar .widget.widget-text ul.download li {
  padding: 0 25px 0 20px;
  margin-bottom: 20px;
  background-color: #364c61;
  position: relative;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.sidebar .widget.widget-text ul.download li:before {
  position: absolute;
  top: 11px;
  font-family: 'FontAwesome';
  content: "\f019";
  color: #fff;
  font-size: 14px;
  right: -15px;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.sidebar .widget.widget-text ul.download li:after {
  content: '';
  position: absolute;
  background-color: #2691e4;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scaleY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.sidebar .widget.widget-text ul.download li:hover {
  padding-left: 15px; }

.sidebar .widget.widget-text ul.download li:hover:before {
  opacity: 1;
  right: 25px; }

.sidebar .widget.widget-text ul.download li:hover:after {
  transform: scaleY(1);
  opacity: 1; }

.sidebar .widget.widget-text ul.download {
  padding-top: 5px; }

.sidebar .widget.widget-text ul.download li a {
  color: #fff;
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
  letter-spacing: 0.1px; }

.sidebar .widget.widget-testimonial {
  padding-top: 20px; }

.owl-carousel.style3 .owl-controls {
  margin-top: 10px; }

.owl-carousel.style3 .owl-controls .owl-dots {
  text-align: left; }

/* Testimonial Item Style3
---------------------------------------------------------------*/
.testimonial-item.style3 blockquote {
  padding: 30px 0 30px;
  margin-bottom: 0;
  background-color: #fff;
  color: #656565;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  z-index: 99;
  position: inherit;
  text-align: left; }

.testimonial-item.style3 .testimonial-content {
  text-align: left; }

.testimonial-item.style3 .testimonial-content .testimonial-image {
  width: 80px;
  height: 80px;
  float: left;
  margin: 0;
  margin-right: 20px;
  border: none; }

.testimonial-item.style3 .testimonial-content .testimonial-author h6 {
  margin-top: 0;
  margin-bottom: 5px; }

.testimonial-item.style3 .testimonial-content .testimonial-author h6 a:not(:hover) {
  color: #18364a; }

.testimonial-item.style3 .testimonial-content .testimonial-author .author-info {
  line-height: 20px; }

/* Article About Post */
article.about-post {
  padding-bottom: 12px;
  margin-bottom: 30px; }

article.about-post .entry-content .img-content {
  margin-bottom: 26px; }

article.about-post .entry-content .content-text {
  overflow: hidden; }

/* Box Item */
.box-item.float {
  margin-right: -15px;
  margin-left: -15px; }

.box-item .text-box {
  padding: 0; }

.box-item .text-box p {
  margin-bottom: 0; }

.box-item .text-box.left,
.box-item .img-box {
  float: left;
  width: 50%;
  padding-right: 15px;
  padding-left: 15px; }

.box-item .text-box ul {
  margin-top: 20px; }

.box-item .text-box ul li {
  position: relative;
  line-height: 30px;
  padding-left: 18px;
  margin-bottom: 5px; }

.box-item .text-box ul li:before {
  position: absolute;
  left: 0;
  top: 13px;
  width: 7px;
  height: 7px;
  background: #2691e4;
  content: "";
  border-radius: 50%; }

.box-item .img-box {
  margin-top: 25px; }

.height18 {
  height: 18px; }

/* Accordion Style2 */
.accordion.style2,
article.about-post.style3 .entry-content {
  margin-bottom: 21.74px; }

.accordion.style2 .accordion-title {
  padding-bottom: 20px;
  padding-top: 40px; }

.accordion.style2 .accordion-title h3 {
  margin-bottom: 0; }

/* Accordion Style3 */
.accordion.style3 {
  overflow: hidden; }

.accordion.style3 .accordion-title {
  padding: 0;
  margin-bottom: 20px; }

.accordion.style3 .accordion-title h3 {
  margin-bottom: 0; }

/* Article About Post Style2 */
article.about-post.style2 .entry-content .img-content {
  margin-bottom: 13px; }

.box-item.style2 .text-box p:not(:last-child) {
  margin-bottom: 20px; }

/* Flat List Year */
.flat-list-year ul {
  margin-top: 57px;
  margin-left: 25px; }

.flat-list-year ul li:not(:last-child) {
  margin-bottom: 10px; }

.flat-list-year ul li span {
  float: left;
  width: 11%;
  font-size: 20px;
  font-weight: 700;
  margin-top: 1px;
  color: #333; }

.flat-list-year ul li div {
  position: relative;
  float: right;
  width: 89%;
  padding-left: 44px;
  padding-bottom: 57px; }

.flat-list-year ul li:last-child div {
  padding-bottom: 0; }

.flat-list-year ul li div:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 18px;
  width: 1px;
  height: 95%;
  background-color: #e3e3e3; }

.flat-list-year ul li div:after {
  content: "";
  position: absolute;
  left: -11px;
  top: 3px;
  width: 11px;
  height: 11px;
  background-color: #2691e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }

.flat-list-year ul li:last-child div:before {
  width: 0; }

.flat-list-year ul li div h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 11px;
  color: #333; }

.flat-list-year ul li div p {
  margin-bottom: 0; }

/* Article About Post Style4 */
article.about-post.style4 .entry-content .img-content {
  margin-bottom: 40px; }

/* Flat Team
---------------------------------------------------------------*/
.flat-team {
  padding: 90px 0; }

/* Team Item */
.team-item .team-image {
  margin-bottom: 17px; }

.team-item .team-image a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative; }

.team-item .team-image a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 76, 97, 0.5);
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.team-item:hover .team-image a:after {
  opacity: 1; }

.team-item .team-info .option {
  font-size: 12px;
  color: #999; }

.team-item .team-info h4 {
  margin-top: 2px;
  margin-bottom: 17px;
  font-size: 18px; }

.team-item .team-info ul.team-social li {
  display: inline-block; }

.team-item .team-info ul.team-social li a {
  color: #fff;
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: #3a6bb1;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.238s ease 0s;
  -moz-transition: all 0.238s ease 0s;
  transition: all 0.238s ease 0s; }

.team-item .team-info ul.team-social li.twitter a {
  background-color: #0ec8f5; }

.team-item .team-info ul.team-social li.google-plus a {
  background-color: #f2623b; }

.team-item .team-info ul.team-social li.linkedin a {
  background-color: #0094d3; }

/* Imagebox
---------------------------------------------------------------*/
.imagebox {
  margin-left: -15px;
  margin-right: -15px; }

.imagebox .imagebox-wrap {
  float: left;
  width: 50%;
  padding-left: 16px;
  padding-right: 15px; }

/* Imagebox Item Style1
---------------------------------------------------------------*/
.imagebox-item {
  text-align: center;
  margin: 0 1.7% 3.3%;
  padding: 30px 30px 38px;
  -webkit-box-shadow: 0px 0px 12px 0px #ccc;
  -moz-box-shadow: 0px 0px 12px 0px #ccc;
  box-shadow: 0px 0px 12px 0px #ccc;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.imagebox-item:hover {
  margin-top: -7px; }

.imagebox-item .imagebox-image {
  margin-top: 38px;
  margin-bottom: 5px; }

.imagebox-item .imagebox-header h3.imagebox-title {
  letter-spacing: -0.2px; }

.imagebox-item .imagebox-header h3.imagebox-title {
  font-size: 18px;
  font-weight: 700;
  color: #2e5797;
  margin-bottom: 1px; }

.imagebox-item .imagebox-header .imagebox-subtitle {
  display: inline-block;
  font-size: 13px;
  font-style: italic; }

.imagebox-item .imagebox-content .imagebox-desc {
  margin-bottom: 14px; }

/* Flat Form Quote
---------------------------------------------------------------*/
.flat-form-quote.style1 .title-quote {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #222; }

.flat-form-quote.style1 p:last-child {
  margin-bottom: 0; }

.flat-form-quote.style1 label {
  display: block;
  width: 500px;
  font-weight: 700;
  margin-bottom: 5px;
  max-width: 100%; }

.flat-form-quote.style1 label span {
  font-weight: 400;
  display: block; }

.flat-form-quote.style1 .title-form {
  font-weight: bold; }

.flat-form-quote.style1 .button-form-quote {
  font-weight: 400; }

.flat-form-quote.style1 label span input {
  margin-bottom: 27px; }

.flat-form-quote.style1 label span select {
  margin-bottom: 30px; }

/* Article About Post Style3 */
article.about-post.style3 .entry-content .img-content {
  margin-bottom: 50px; }

.height25 {
  height: 25px; }

/* Box Item Style3 */
.box-item.style3 .text-box h2 {
  margin-top: 0;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #222;
  line-height: 27px; }

.box-item.style3 .text-box .services-textblock-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 34px; }

/* Roll Tabs */
.roll-tabs {
  margin-top: 50px; }

.roll-tabs ul.tabs-list {
  word-spacing: -4px;
  margin: -1px 0 0 -1px; }

.roll-tabs ul.tabs-list li {
  display: inline-block;
  margin: 1px 0 0 1px; }

.roll-tabs ul.tabs-list li span {
  border: 1px solid #f0f0f0;
  background-color: transparent;
  color: #666;
  border-bottom: transparent;
  cursor: pointer;
  padding: 14px 20px 14px 19.5px;
  display: block;
  box-sizing: border-box;
  border-bottom: transparent;
  position: relative; }

.roll-tabs ul.tabs-list li.active span,
.roll-tabs ul.tabs-list li:hover span {
  background-color: #f8f8f8; }

.roll-tabs .content-tab .content-inner {
  border: 1px solid #f0f0f0;
  box-sizing: content-box;
  padding: 14px 20px;
  display: none;
  border-radius: 5px;
  border-top-left-radius: 0px;
  line-height: 24px;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: padding .2s ease-in-out; }

.roll-tabs .content-tab .content-inner p img {
  margin: 7px 30px 25px -1px;
  float: left; }

/* Roll-tabs Style2 */
.roll-tabs.style2 ul.tabs-list li span {
  border-color: #e3e3e3;
  background-color: #EBEBEB; }

.roll-tabs.style2 ul.tabs-list li.active span {
  background-color: #f8f8f8; }

.roll-tabs.style2 ul.tabs-list li:hover span {
  background-color: #dcdcdc; }

/* Parallax
---------------------------------------------------------------*/
.parallax {
  width: 100%;
  background-attachment: fixed;
  background-position: 100% 0;
  background-size: cover;
  position: relative;
  z-index: 2; }

.parallax1 {
  background-image: url("../img/parallax/01.jpg"); }

.parallax2 {
  background-image: url("../img/parallax/02.jpg"); }

.parallax3 {
  background-image: url("../img/parallax/03.jpg"); }

.parallax4 {
  background-image: url("../img/parallax/04.jpg"); }

.parallax > .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background: rgba(24, 54, 74, 0.95); }

/* Tp Banner */
.tp-caption.title-slide, .title-slide {
  color: #222222;
  font-size: 58px;
  line-height: 75px;
  font-weight: 700;
  font-style: normal;
  font-family: Poppins;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px; }

.tp-caption.button-slide, .button-slide,
.tp-caption.button-slide-color, .button-slide-color {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: Poppins;
  text-decoration: none;
  background-color: #2691e4;
  border-color: #000000;
  border-style: solid;
  border-width: 0px;
  border-radius: 3px 3px 3px 3px; }

.fullwidthbanner-container .fullwidthabanner .tparrows {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: transparent;
  border: 2px solid #fff;
  background: rgba(0, 0, 0, 0) !important;
  opacity: 0; }

.fullwidthbanner-container .fullwidthabanner:hover .tparrows {
  opacity: 1; }
